import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { USD } from '../../helpers/NumberHelper'
import { uniformUrl } from '../../helpers/StringHelpers'
import { ProductTypeHelper } from '../../helpers/ProductTypeHelper'
import { ProductShortTitle } from '../products/ProductTitle'
import CategoryImage from '../CategoryImage'
import { IconHeart } from '../popups/FavoriteItem'
import { AllowedTags } from '../products/Tags'
import finalPrice from '../../helpers/product/finalPrice'

const ProductSliderFeaturedCard = ({ product, tags = true, openFavorite = null, group, index }) => {
  const isSale = product?.special_price === null
  const url = uniformUrl((product.url || product.urlPath) + '/')
  const onClick = useCallback(
    e => {
      e.preventDefault()
      if (openFavorite) openFavorite(product)
    },
    [product, openFavorite]
  )
  const productType = product.productType || ProductTypeHelper(product)
  const isWatch = productType.endsWith('Watch')
  return (
    <Link
      to={url}
      className={
        'group bg-white relative my-2 flex w-full flex-col items-center justify-center gap-2 rounded-md border p-4 shadow-lg duration-300 ease-out hover:scale-[102%] ' +
        (group === 0 && index === 0 ? ' col-span-2 row-span-2' : 'aspect-square')
      }
    >
      <button onClick={onClick}>
        <IconHeart className="absolute top-2 right-2 z-10 h-8 w-8 rounded-full bg-white p-[.3rem] text-gray-200 duration-700 group-hover:text-red-500" />
      </button>

      <CategoryImage
        sku={product.sku}
        alt={product.name}
        title={product.title}
        normal={product.small_image}
        productType={productType}
        tags={tags}
        imgStyle={{ objectFit: 'contain' }}
        product={product}
        className={'aspect-square w-full h-full'}
      />
    </Link>
  )
}

// create prop types validation for ProductSliderCard
ProductSliderFeaturedCard.propTypes = {
  product: PropTypes.object.isRequired,
  openFavorite: PropTypes.func,
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
}

export default ProductSliderFeaturedCard
