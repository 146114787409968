import React, { useState, useMemo } from 'react'
import ProductSliderFeaturedCard from './ProductSliderFeaturedCard'
import PropTypes from 'prop-types'
import Slider from './Slider'
import { BlogSliderButtonNext, BlogSliderButtonPrev } from './slider/BlogSliderButton'
import { AllowedTags } from '../products/Tags'
import FavoriteItems from '../popups/FavoriteItem'

export const ProductSliderItemsPerScreen = () => {
  const itemsPerScreen = React.useCallback(() => {
    return 1 
  }, [])

  return [itemsPerScreen, 'w-full']
}

const groupProducts = products => {
  if (!products.length) return []
  const groups = [products.slice(0, 3)]
  for (let i = 3; i < products.length; i += 6) {
    groups.push(products.slice(i, i + 6))
  }
  return groups
}

const ProductSliderFeatured = ({
  h2Title,
  products,
  tags = true,
  step = 0,
  className = 'md:py-10',
  itemsPerScreenFactory = ProductSliderItemsPerScreen,
}) => {
  const [fav, openFavorite] = useState(null)
  const groupedProducts = useMemo(() => groupProducts(products), [products])
  if (!groupedProducts.length) return null

  return (
    <section className={'max-w-[1366px] mx-auto ' + className}>
      {h2Title && <h2 className="underline-title">{h2Title}</h2>}

      <Slider
        buttonPrev={BlogSliderButtonPrev}
        buttonNext={BlogSliderButtonNext}
        childrenClassName={'flex-none snap-start p-2 md:pb-10 z-0'}
        className={'sm:p-6'}
        step={step}
        itemsPerScreenFactory={itemsPerScreenFactory}
      >
        {groupedProducts.map((group, groupIndex) => (
          <div
            key={groupIndex}
            className="grid gap-4 grid-cols-3 grid-flow-col grid-rows-2 px-0 md:px-[54px]"
          >
            {group.map((product, productIndex) => (
              <ProductSliderFeaturedCard
                key={product.sku}
                group={groupIndex}
                index={productIndex}
                product={product}
                tags={tags}
                openFavorite={openFavorite}
              />
            ))}
          </div>
        ))}
      </Slider>

      {fav && (
        <FavoriteItems
          className="absolute top-2 right-2 z-10"
          product={fav}
          setFavorite={openFavorite}
        />
      )}
    </section>
  )
}

ProductSliderFeatured.propTypes = {
  h2Title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
  step: PropTypes.number,
  className: PropTypes.string,
  itemsPerScreenFactory: PropTypes.func,
}

export default ProductSliderFeatured
